import NiceModal from '@ebay/nice-modal-react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC } from 'react'

import {
  constants,
  FILTER_VEHICLE_TYPES_CARS,
  FILTER_VEHICLE_TYPES_VANS,
} from '@/lib/constants'
import { ROUTES } from '@/lib/routes'
import { shimmer, toBase64 } from '@/lib/utilities/image'
import { getCurrentYear } from '@/lib/utilities/system'
import { addToDataLayer } from '@/lib/utilities/vehicle'
import { Size } from '@/types/system'
import { FeedbackModalProps } from '@/components/modals/FeedbackModal'
import { Button, ButtonHierarchy } from '../Button'
import { ExternalLink, InternalLink } from '../Link'
import { NewsletterWidget } from '../NewsletterWidget'
import { TrustpilotWidget } from '../TrustpilotWidget'
import styles from './footer.module.scss'

const FeedbackModal = dynamic(() => import('@/components/modals/FeedbackModal')) as FC<FeedbackModalProps>

export const Footer = () => {
  const router = useRouter()
  const currentYear = getCurrentYear()

  const isVans = router.asPath.includes(ROUTES.vanHomePage.url)
  const vehicleType = isVans ? FILTER_VEHICLE_TYPES_VANS : FILTER_VEHICLE_TYPES_CARS

  const shouldHideFooter =
    router.asPath.match(ROUTES.vehicleConfigPage.regex.van) ||
    router.asPath.match(ROUTES.vehicleConfigPage.regex.car) ||
    router.asPath.match(ROUTES.enquiryLandingPage.regex)

  const shouldShowSellYourVehicleMarks =
    router.asPath.match(ROUTES.homePage.regex) ||
    router.asPath.match(ROUTES.vanHomePage.regex) ||
    router.asPath.match(ROUTES.vehicleDealsPage.regex.van) ||
    router.asPath.match(ROUTES.vehicleDealsPage.regex.car) ||
    router.asPath.match(ROUTES.vehicleConfigPage.regex.van) ||
    router.asPath.match(ROUTES.vehicleConfigPage.regex.car) ||
    router.asPath.match(ROUTES.vehicleEnquiredPage.regex.car) ||
    router.asPath.match(ROUTES.vehicleEnquiredPage.regex.van)

  const shouldShowPcpSavingsMarks = router.asPath.match(ROUTES.homePage.regex) ||
    router.asPath.match(ROUTES.vanHomePage.regex)

  const onClickSocialLink = (link: string) => {
    addToDataLayer({
      event: 'footer',
      action: 'social-icon-click',
      link,
    })
  }

  return (
    <footer className={`${styles.main} ${shouldHideFooter ? styles.hide : ''}`}>
      <div className={styles.wrapper}>
        <section className={styles.trustpilot}>
          <TrustpilotWidget
            rating={constants.trustpilot.score}
            type="inline"
            link={`${constants.trustpilot.reviewUrl}/${constants.trustpilot.link}`}
          />
        </section>
        <section className={styles.stacked}>
          <div className={styles.social}>
            <NewsletterWidget
              isInline
              hasLargeTitle
              location="footer"
            />
            <ul className={styles.accounts}>
              <li>
                <ExternalLink
                  href="https://www.facebook.com/leaseloco/"
                  target="_blank"
                  title="Facebook"
                  rel="noopener noreferrer"
                  className={styles.facebook}
                  onClick={() => onClickSocialLink('facebook')}
                  hierarchy={ButtonHierarchy.BezeledGray}
                  size={Size.ExtraLarge}>
                  <Image
                    width="27"
                    height="27"
                    alt="Facebook"
                    src={`${constants.imageHost.assets}/icons/social/facebook.svg`}
                  />
                  <span className="sr-only">LeaseLoco Facebook Page</span>
                </ExternalLink>
              </li>
              <li>
                <ExternalLink
                  href="https://www.instagram.com/leaseloco"
                  target="_blank"
                  title="Instagram"
                  rel="noopener noreferrer"
                  className={styles.instagram}
                  onClick={() => onClickSocialLink('instagram')}
                  hierarchy={ButtonHierarchy.BezeledGray}
                  size={Size.ExtraLarge}>
                  <Image
                    width="24"
                    height="24"
                    alt="Instagram"
                    src={`${constants.imageHost.assets}/icons/social/instagram.svg`}
                  />
                  <span className="sr-only">LeaseLoco Instagram Page</span>
                </ExternalLink>
              </li>
              <li>
                <ExternalLink
                  href="https://www.twitter.com/leaseloco"
                  target="_blank"
                  title="X (formerly Twitter)"
                  rel="noopener noreferrer"
                  className={styles.x}
                  onClick={() => onClickSocialLink('twitter')}
                  hierarchy={ButtonHierarchy.BezeledGray}
                  size={Size.ExtraLarge}>
                  <Image
                    width="25"
                    height="22"
                    alt="X (formerly Twitter)"
                    src={`${constants.imageHost.assets}/icons/social/twitter-x.svg`}
                  />
                  <span className="sr-only">LeaseLoco X Page</span>
                </ExternalLink>
              </li>
              <li>
                <ExternalLink
                  href="https://www.youtube.com/leaseloco"
                  target="_blank"
                  title="YouTube"
                  rel="noopener noreferrer"
                  className={styles.youtube}
                  onClick={() => onClickSocialLink('youtube')}
                  hierarchy={ButtonHierarchy.BezeledGray}
                  size={Size.ExtraLarge}>
                  <Image
                    width="30"
                    height="21"
                    alt="YouTube"
                    src={`${constants.imageHost.assets}/icons/social/youtube.svg`}
                  />
                  <span className="sr-only">LeaseLoco YouTube Page</span>
                </ExternalLink>
              </li>
              <li>
                <ExternalLink
                  href="https://www.linkedin.com/company/leaseloco"
                  target="_blank"
                  title="LinkedIn"
                  rel="noopener noreferrer"
                  className={styles.linkedin}
                  onClick={() => onClickSocialLink('linkedin')}
                  hierarchy={ButtonHierarchy.BezeledGray}
                  size={Size.ExtraLarge}>
                  <Image
                    width="25"
                    height="25"
                    alt="LinkedIn"
                    src={`${constants.imageHost.assets}/icons/social/linkedin.svg`}
                  />
                  <span className="sr-only">LeaseLoco LinkedIn Page</span>
                </ExternalLink>
              </li>
              <li>
                <ExternalLink
                  href="https://www.tiktok.com/@leaseloco"
                  target="_blank"
                  title="TikTok"
                  rel="noopener noreferrer"
                  className={styles.tiktok}
                  onClick={() => onClickSocialLink('tiktok')}
                  hierarchy={ButtonHierarchy.BezeledGray}
                  size={Size.ExtraLarge}>
                  <Image
                    width="24"
                    height="28"
                    alt="tiktok"
                    src={`${constants.imageHost.assets}/icons/social/tiktok.svg`}
                  />
                  <span className="sr-only">LeaseLoco TikTok Page</span>
                </ExternalLink>
              </li>
            </ul>
          </div>
          <div className={styles.navigation}>
            <ul className={styles.list}>
              {constants.footer.firstColumn.map((item: { label: string; slug: string }) => {
                return (
                  <li key={item.slug}>
                    <InternalLink
                      href={item.slug}
                      size={Size.Large}
                      hierarchy={ButtonHierarchy.BezeledGray}
                      className={styles.link}>
                      {item.label}
                    </InternalLink>
                  </li>
                )
              })}
              <li>
                <Button
                  label="Contact us"
                  size={Size.Large}
                  hasBackground={false}
                  hasPadding={false}
                  hasTransition={false}
                  hierarchy={ButtonHierarchy.BezeledGray}
                  className={styles.link}
                  onClick={() => NiceModal.show(FeedbackModal)}
                />
              </li>
              <li>
                <Button
                  label="Advertise with us"
                  size={Size.Large}
                  hasBackground={false}
                  hasPadding={false}
                  hasTransition={false}
                  hierarchy={ButtonHierarchy.BezeledGray}
                  className={styles.link}
                  onClick={() =>
                    NiceModal.show(FeedbackModal, {
                      isAdvertisingModal: true,
                    })
                  }
                />
              </li>
            </ul>
            <ul className={styles.list}>
              {constants.footer.secondColumn.map((item: { label: string; slug: string }) => {
                return (
                  <li key={item.slug}>
                    <InternalLink
                      href={item.slug}
                      size={Size.Large}
                      hierarchy={ButtonHierarchy.BezeledGray}
                      className={styles.link}>
                      {item.label}
                    </InternalLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </section>
        <section className={styles.popular}>
          <p>Popular lease searches:</p>
          <ul className={styles.list}>
            {constants.footerLinks
              .filter(item => item.vehicleType === vehicleType)
              .map(item => {
                return (
                  <li key={item.slug}>
                    <InternalLink
                      hasBackground
                      hasPadding
                      href={item.slug}
                      hierarchy={ButtonHierarchy.BezeledGray}
                      size={Size.Medium}>
                      {item.label}
                    </InternalLink>
                  </li>
                )
              })}
          </ul>
        </section>
        <section className={styles.legal}>
          <ul className={styles.list}>
            <li>
              <InternalLink
                href={ROUTES.privacyPolicyPage.url}
                hasUnderline
                hierarchy={ButtonHierarchy.BezeledGray}
                size={Size.Medium}>
                Privacy
              </InternalLink>
            </li>
            <li>
              <InternalLink
                href={ROUTES.termsPage.url}
                hasUnderline
                hierarchy={ButtonHierarchy.BezeledGray}
                size={Size.Medium}>
                Terms
              </InternalLink>
            </li>
            <li>
              <InternalLink
                href={ROUTES.cookiePolicyPage.url}
                hasUnderline
                hierarchy={ButtonHierarchy.BezeledGray}
                size={Size.Medium}>
                Cookies
              </InternalLink>
            </li>
            <li>
              <InternalLink
                href={ROUTES.complaintsPage.url}
                hasUnderline
                hierarchy={ButtonHierarchy.BezeledGray}
                size={Size.Medium}>
                Complaints procedures
              </InternalLink>
            </li>
            <li>
              <InternalLink
                href={ROUTES.treatingCustomersFairlyPage.url}
                hasUnderline
                hierarchy={ButtonHierarchy.BezeledGray}
                size={Size.Medium}>
                Treatment of customers
              </InternalLink>
            </li>
          </ul>
          <ul className={`${styles.affiliates} ${styles.list}`}>
            <li>
              <a
                href="https://www.bvrla.co.uk"
                target="_blank"
                rel="noopener noreferrer">
                <Image
                  src={`${constants.imageHost.assets}/general/bvrla-logo-dark.svg`}
                  alt="British Vehicle Rental &amp; Leasing Association (BVRLA)"
                  placeholder="blur"
                  blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(86, 48))}`}
                  width="86"
                  height="48"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.cap-hpi.com"
                target="_blank"
                rel="noopener noreferrer">
                <Image
                  src={`${constants.imageHost.assets}/general/solera-logo-dark.svg`}
                  alt="Solera"
                  className="image--cap"
                  placeholder="blur"
                  blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(280, 32))}`}
                  height="32"
                  width="280"
                />
              </a>
            </li>
          </ul>
        </section>
        <section className={styles.additional}>
          <p>
            Leaseloco Ltd is authorised and regulated by the Financial Conduct Authority in relation
            to consumer credit. Our FCA number is {constants.financialConductAuthority}. We are a
            credit broker and not a lender and may introduce you to one of a number of companies
            offering consumer hire or consumer hire-purchase. Leaseloco Ltd is registered in
            Scotland ({constants.companiesHouseNumber}), address: {constants.companyAddress}. Data
            Protection Number: {constants.dataProtectionNumber}. VAT Number: {constants.vatNumber}.
          </p>
          <p>Copyright &copy; 2017 - {currentYear} Leaseloco Ltd. All Rights Reserved.</p>
          {shouldShowSellYourVehicleMarks && (
            <p>
              <sup>1</sup> 84% of Motorway sellers sold their vehicle for more than the average
              market price valuation between Jan 2020 and October 2023 (based on two independent
              market pricing guides).
            </p>
          )}
          {shouldShowPcpSavingsMarks && (
            <p>
              <sup>2</sup> Our users saved over £30m based on all message enquiries on LeaseLoco on March 2024, when compared to estimated PCP monthly payments over the same term.
            </p>
          )}
        </section>
      </div>
      <span className={styles.ring}></span>
    </footer>
  )
}
