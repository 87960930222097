import Image from 'next/image'

import { constants } from '../../../lib/constants'
import { getTrustpilotLabel, getTrustpilotStarRating } from '../../../lib/utilities/page'
import { Size } from '../../../types/system'
import styles from './trustpilot.module.scss'

export type TrustpilotWidgetProps = {
  rating: number
  type: 'inline' | 'stacked'
  size?: Size
  link?: string
}
export const TrustpilotWidget = ({
  rating,
  type,
  link,
  size = Size.Medium,
}: TrustpilotWidgetProps) => {
  if (!rating) {
    return false
  }

  const renderMainComponent = () => (
    <div
      className={styles.main}
      data-layout-type={type}
      data-size={size}>
      <p className={styles.inline}>
        Rated <span className={styles['rating-label']}>{getTrustpilotLabel(rating)}</span>
      </p>
      <div className={styles.stars}>
        <Image
          src={`${constants.imageHost.assets}/branding/tp-stars-${getTrustpilotStarRating(
            rating,
          )}.svg`}
          height="96"
          width="512"
          alt="Trustpilot score"
        />
      </div>
      <div className={`${styles.inline} ${styles.logo}`}>
        <Image
          src={`${constants.imageHost.assets}/branding/tp-logo.svg`}
          height="18"
          width="81"
          alt="Trustpilot logo"
        />
      </div>
      <p className={styles.stacked}>
        Rated <span className={styles['rating-label']}>{getTrustpilotLabel(rating)}</span> on
        Trustpilot
      </p>
    </div>
  )

  if (link) {
    return (
      <a
        className={styles.link}
        href={link}
        target="_blank"
        rel="noreferrer">
        {renderMainComponent()}
      </a>
    )
  } else {
    return renderMainComponent()
  }
}
